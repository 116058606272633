import {AnimatePresence} from 'framer-motion'

import {Email} from './Email'
import {EmailGroup} from './EmailGroup'
import {groupEmailsBySub} from './hooks/useEmails'

type EmailsProps = {
  emails: Email[]
  grouped?: boolean
}

export function Emails({grouped, emails}: EmailsProps) {
  return (
    <AnimatePresence>
      {grouped
        ? Object.values(groupEmailsBySub(emails)).map((emails) =>
            emails.length === 1 ? (
              <Email key={emails[0]!.id} {...emails[0]!} />
            ) : (
              <EmailGroup
                key={emails[0]!.sender.id}
                sender={emails[0]!.sender}
                emails={emails}
              />
            ),
          )
        : emails.map((email) => <Email key={email.id} {...email} />)}
    </AnimatePresence>
  )
}
