import {queryClient} from '~/utilities/queries'

/**
 * Updates a sub's email by id.
 */
export function updateSubEmail(subSlug: string, {id, ...rest}: Email) {
  queryClient.setQueryData<Sub>(['sub', subSlug], (oldData) => {
    if (!oldData) return oldData

    return {
      ...oldData,
      emails: oldData.emails.map((email) => {
        if (email.id === id) {
          return {...email, ...rest}
        }
        return email
      }),
    }
  })
}

/**
 * Updates a sub's emails by setting their attributes.
 */
export function setSubEmailsAttributes(
  subSlug?: string,
  emailAttributes?: Partial<Email>,
) {
  queryClient.setQueryData<Sub>(['sub', subSlug], (oldData) => {
    if (!oldData) return oldData

    return {
      ...oldData,
      emails: oldData.emails.map((email) => ({
        ...email,
        ...emailAttributes,
      })),
    }
  })
}
