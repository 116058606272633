import {
  createContext,
  useContext,
  type SetStateAction,
  type Dispatch,
} from 'react'

export type FeedContext = {
  enableCustomScrollbar: boolean
  isEmpty?: boolean
  readEmailIds: string[]
  unreadEmailIds: string[]
}

// TODO: this is pretty global, replace with Zustand or sth & move to separate file.
export const feedContext = createContext<
  [FeedContext, Dispatch<SetStateAction<FeedContext>>]
>([
  {
    enableCustomScrollbar: true,
    readEmailIds: [],
    unreadEmailIds: [],
  },
  () => {},
])

export function useFeedContext() {
  return useContext(feedContext)
}
