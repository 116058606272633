import {useMutation, type UseMutationOptions} from '@tanstack/react-query'

import {fetcher, queryClient} from '~/utilities/queries'
import {setSubEmailsAttributes} from '~/utilities/cache/subs'
import {useFeedContext} from '~/routes/Dashboard/context'

async function markEmailsRead(ids: number[]) {
  return await fetcher('/api/emails/mark_group_as_read', {
    method: 'PUT',
    body: {email_ids: ids},
  })
}

export function useMarkEmailsAsRead(
  emails: Email[],
  options: UseMutationOptions = {},
) {
  const [_, setFeedContext] = useFeedContext()

  const emailIds = emails.map(({id}) => id)
  const sub = emails[0]?.sender

  return useMutation({
    mutationFn: async () => await markEmailsRead(emailIds),
    onMutate: async () => {
      // Set cached emails as read.
      emails.forEach((email) => {
        queryClient.setQueryData<{email: Email}>(
          ['email', `${email.id}`],
          (oldData) => ({
            email: {
              ...oldData!.email,
              read: true,
            },
          }),
        )
      })

      // Set cached sub emails as read.
      setSubEmailsAttributes(sub?.slug, {read: true})

      // Update feed context, so that when we navigate to the homepage, the "homeFeed" query emails are updated.
      setFeedContext((value) => {
        const stringEmailIds = emailIds.map(String)
        const newReadEmailIds = [...value.readEmailIds, ...stringEmailIds]
        const newUnreadEmailIds = value.unreadEmailIds.filter(
          (id) => !stringEmailIds.includes(id),
        )

        return {
          ...value,
          readEmailIds: newReadEmailIds,
          unreadEmailIds: newUnreadEmailIds,
        }
      })
    },
    ...options,
  })
}
