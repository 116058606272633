import {produce} from 'immer'

export function updateHomeFeedCache(email: Email, oldData?: Email[]) {
  try {
    if (!oldData) return oldData

    return produce(oldData, (draft) => {
      const emailIndex = draft.findIndex((e) => e.id === email.id)

      if (emailIndex !== -1) {
        draft[emailIndex] = email
      }
    })
  } catch (error) {
    return oldData
  }
}
