import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useMemo} from 'react'
import {useParams} from 'react-router-dom'
import set from 'lodash/set'

import {useAuthContext} from '~/providers/Auth'

import {getEmail} from '../EmailPreview'
import {useTopic} from '~/components/Inbox/routes/Topic/hooks'
import {useSub} from '~/components/Inbox/routes/Sub/hooks'

export type GroupedEmails = {[key: string]: Email[]}

export function sortEmails(emails: Email[]) {
  return emails.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}

export function groupEmailsBySub(emails: Email[]) {
  return emails.reduce<GroupedEmails>((acc, email) => {
    acc[`sub_${email.sender.id}`] = [
      ...(acc[`sub_${email.sender.id}`] || []),
      email,
    ]

    return acc
  }, {})
}

export function emailsByTopic(emails: Email[]) {
  return emails.reduce(
    (acc, email) => {
      for (const topic of email.sender.topics) {
        set(acc, `${topic.name}`, [...(acc[topic.name] || []), email])
      }

      return acc
    },
    {} as {[key: string]: Email[]},
  )
}

export function filterEmails(emails: Email[] = [], feedView: FeedViewSetting) {
  if (!emails || emails?.length === 0) return []

  return emails.filter((email) => {
    if (feedView === 'unread') {
      return !email.read
    } else if (feedView === 'read') {
      return email.read
    }

    return true
  })
}

export function useEmails() {
  const {currentUser} = useAuthContext()
  const params = useParams<{slug?: string; topicName?: string}>()
  const sub = params.slug ? useSub() : null
  const {topic, emails: topicEmails} = params.topicName
    ? useTopic()
    : {topic: null, emails: []}
  const {data: homeEmails} = useQuery<Email[]>({queryKey: ['homeEmails']})

  const emails: Email[] = useMemo(() => {
    if (sub !== null) return sortEmails(sub.emails)
    if (topic !== null) {
      return sortEmails(topicEmails)
    }
    if (homeEmails) {
      return filterEmails(
        sortEmails(homeEmails),
        currentUser!.settings.feedView,
      )
    }

    return []
  }, [sub, topic, homeEmails])

  return emails
}

type EmailFetcherOptions = {
  skipQuery?: boolean
  refetchOnWindowFocus?: boolean
} & Omit<UseQueryOptions<{email: Email}>, 'queryKey' | 'queryFn' | 'enabled'>

export function useEmailFetcher({
  skipQuery,
  ...options
}: EmailFetcherOptions = {}) {
  const params = useParams<{emailId: string}>()

  return useQuery<{email: Email}>({
    queryKey: ['email', params.emailId],
    queryFn: async () => await getEmail(params.emailId!),
    enabled: !skipQuery,
    ...options,
  })
}
