import {
  createBrowserRouter,
  createHashRouter,
  type RouteObject,
} from 'react-router-dom'
import {Suspense} from 'react'
import loadable from '@loadable/component'

import {currentUserOrRedirect} from '~/providers/Auth'
import {getEmails, Home} from '~/components/Inbox/routes/Home/Home'
import {EmailPreview} from '~/components/Email/EmailPreview'
import {queryClient} from '~/utilities/queries'

const emailRoute: RouteObject = {
  path: 'emails/:emailId',
  element: <EmailPreview />,
}

const InboxSkeleton = loadable(
  () => import('~/components/Inbox/components/InboxSkeleton/InboxSkeleton'),
  {
    resolveComponent: (component) => component.InboxSkeleton,
  },
)
const Dashboard = loadable(() => import('./Dashboard/Dashboard'), {
  resolveComponent: (component) => component.Dashboard,
  fallback: <InboxSkeleton />,
})
const Login = loadable(() => import('./Login/Login'), {
  resolveComponent: (component) => component.Login,
})
const Inbox = loadable(() => import('~/components/Inbox/Inbox'), {
  resolveComponent: (component) => component.Inbox,
  fallback: <InboxSkeleton />,
})
const Sub = loadable(() => import('~/components/Inbox/routes/Sub/Sub'), {
  resolveComponent: (component) => component.Sub,
})
const Topic = loadable(() => import('~/components/Inbox/routes/Topic/Topic'), {
  resolveComponent: (component) => component.Topic,
})

// Seeing as Electron app serves app through file system, we need hash router.
const platformRouter = (globalThis as any).IS_DESKTOP_CLIENT
  ? createHashRouter
  : createBrowserRouter

export const router = platformRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<InboxSkeleton />}>
        <Dashboard />
      </Suspense>
    ),
    loader: async () => await currentUserOrRedirect('loggedIn'),
    children: [
      {
        id: 'inbox',
        path: '/',
        loader: async () => {
          return await queryClient.ensureQueryData({
            queryKey: ['homeEmails'],
            queryFn: getEmails,
          })
        },
        element: (
          <Suspense fallback={<InboxSkeleton />}>
            <Inbox />
          </Suspense>
        ),
        children: [
          {
            path: '/',
            element: <Home />,
          },
          emailRoute,
          {
            id: 'sub',
            path: '/subs/:slug',
            children: [
              {
                path: '',
                element: <Sub />,
              },
              emailRoute,
            ],
          },
          {
            id: 'topic',
            path: '/topics/:topicName',
            children: [
              {
                path: '',
                element: <Topic />,
              },
              emailRoute,
            ],
          },
          {
            path: '*',
            element: <>We were unable to find what you're looking for.</>,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
    loader: async () => await currentUserOrRedirect('loggedOut'),
  },
])
