import {useEffect} from 'react'
import {useQuery} from '@tanstack/react-query'

import {filterEmails} from '~/components/Email/hooks/useEmails'
import {useFeedContext} from '~/routes/Dashboard/context'
import {fetcher, queryClient} from '~/utilities/queries'
import {useAuthContext} from '~/providers/Auth'
import {Emails} from '~/components/Email/Emails'

import {FeedEmptyState} from '../../components/Feed/FeedEmptyState'

export async function getEmails() {
  const emails = await fetcher<Email[]>('/api/emails')

  for (const email of emails) {
    queryClient.setQueryData(['email', email.id.toString()], {email})
  }

  return emails
}

export function Home() {
  const {data} = useQuery<Email[]>({
    queryKey: ['homeEmails'],
  })
  const [_, setFeedContext] = useFeedContext()
  const {currentUser} = useAuthContext()

  const filteredFeedEmails = filterEmails(data, currentUser!.settings.feedView)

  useEffect(() => {
    setFeedContext((value) => ({
      ...value,
      enableCustomScrollbar: filteredFeedEmails.length !== 0,
    }))
  }, [data, currentUser!.settings, filteredFeedEmails.length])

  if (filteredFeedEmails.length === 0)
    return <FeedEmptyState title="All done." />

  return (
    <Emails
      grouped={currentUser!.settings.feedGrouping === 'grouped'}
      emails={filteredFeedEmails}
    />
  )
}
